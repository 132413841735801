html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
}

.maincolor {
  color: #1758a0;
}

.secondcolor {
  color: #d83831;
}

.barlow {
  font-family: "Barlow", sans-serif;
}

/* .lato {
  font-family: "Lato", sans-serif;
} */

.sticky-top {
  z-index: 1 !important;
}

.title-barlow {
  font-family: "Barlow", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  color: white;
  font-size: 45px;
  line-height: 50px;
}

.title-barlow-bold {
  font-family: "Barlow", sans-serif;
  font-weight: 900;
  font-size: 90px;
  text-transform: uppercase;
  color: white;
  line-height: 90px;
}

.header-top {
  background-color: #1758a0;
  width: 100%;
  height: 65px;
  margin-top: -65px;
  /* position: absolute; */
  text-align: center;
}

.navbartop {
  height: 65px;
  background-color: #1758a0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: row; */
}

.navbartop a {
  text-decoration: none;
  text-transform: uppercase;
  /* background-color: #fff;
  color: #1758a0; */
  color: #fff;
  font-size: 18px;
  padding: 20px 35px;
  font-family: "Lato", sans-serif;
}

.navbartop a:hover {
  background-color: #fff;
  color: #1758a0;
  font-family: "Lato", sans-serif;
}

.navbartop .active {
  text-decoration: none;
  text-transform: uppercase;
  background-color: #fff;
  color: #1758a0;
  font-size: 18px;
  padding: 20px 35px;
  font-family: "Lato", sans-serif;
}

.footer {
  height: auto;
  background-color: #1758a0;
  text-align: center;
  display: flex;
  align-items: center;
  text-align: center;
}

.footer a {
  text-decoration: none;
  color: #fff;
  font-size: 15px;
  line-height: 40px;
  padding: 20px 20px;
  font-family: "Lato", sans-serif;
}

.footer a:hover {
  text-decoration: none;
  color: #fff;
  font-size: 15px;
  line-height: 40px;
  padding: 10px 20px;
  font-family: "Lato", sans-serif;
  border-bottom: 2px solid #fff;
}

.footer .pres-link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.footer .pres-link a:hover {
  text-decoration: none;
  /* color: #fff;
  font-size: 15px;
  line-height: 40px;
  padding: 10px 20px; */
  font-family: "Lato", sans-serif;
  border-bottom: unset;
}

.home-main-title {
  font-family: "Barlow", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  color: #1758a0;
  font-size: 40px;
  line-height: 48px;
  padding-bottom: 20px;
}

.video {
  width: 100%;
  margin: 0 0 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video video {
  width: 100%;
  max-width: 900px;
  outline: none !important;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
}

.home-partenaires-image img {
  max-width: 170px;
  width: 100%;
  margin: 0 30px;
}

.decouverte-metiers-bg {
  background-image: linear-gradient(
      to right bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("../../public/assets/img/soudure.jpg");
  height: 530px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  padding: 0;
  --bs-gutter-x: 0 !important;
}

.home-card-title {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-size: 19px;
  /* line-height: 48px; */
  padding-bottom: 10px;
  text-align: center;
}

.home-section-description {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* z-index: 500; */
  position: relative;
}

.home-section-description span {
  color: #1758a0;
  background: #fff;
  display: inline-block;
  padding: 15px;
  font-size: 50px;
  font-weight: 900;
  font-family: "Barlow", sans-serif;
  text-transform: uppercase;
  line-height: 60px;
  margin: 3px 0;
  border-radius: 3px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
}

.home-title-pratique {
  color: #d83831;
  text-transform: uppercase;
  margin-bottom: 30px;
  font-size: 40px;
  font-weight: 900;
  font-family: "Barlow", sans-serif;
}

.home-subtitle-pratique {
  color: #1758a0;
  text-transform: uppercase;
  margin: 60px 0 15px 0;
  font-size: 21px;
  font-weight: 900;
  font-family: "Barlow", sans-serif;
}

.section-home-card-bg1 {
  background-color: #1758a0;
  position: relative;
}

.section-home-card-bg1:hover {
  background-color: #000;
}

.section-home-card-bg2 {
  background-color: #d83831;
  position: relative;
}

.section-home-card-bg2:hover {
  background-color: #000;
}

.section-home-card-bg3 {
  background-color: #1758a0;
  position: relative;
}

.section-home-card-bg3:hover {
  background-color: #000;
}

.card-number {
  position: absolute;
  left: 0;
  top: -0;
  margin: 0 !important;
  line-height: 1 !important;
  color: #fff;
  font-weight: 900;
  font-family: "Barlow", sans-serif;
  font-size: 70px;
  z-index: 999;
  transform: translateY(calc(-97% + 5px));
}

.card-number2 {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0 !important;
  line-height: 1 !important;
  color: #fff;
  font-weight: 900;
  font-family: "Barlow", sans-serif;
  font-size: 70px;
  z-index: 999;
  transform: translateY(calc(-97% + 5px));
}

.zoom {
  overflow: hidden;
}

.zoom img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.zoom :hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.title-page {
  font-family: "Barlow", sans-serif;
  font-weight: 900;
  font-size: 40px;
  text-transform: uppercase;
  color: #1758a0;
  line-height: 48px;
  margin-top: 30px;
  margin-bottom: 25px;
}

.title-entreprise {
  font-family: "Barlow", sans-serif;
  font-weight: 900;
  font-size: 28px;
  text-transform: uppercase;
  color: #1758a0;
  line-height: 32px;
  margin-top: 30px;
  margin-bottom: 25px;
}

.programme .card .bg-blue {
  --bs-card-border-width: 0 !important;
  --bs-card-bg: none !important;
  color: #fff !important;
  background-color: #1758a0;
}

.programme .card .bg-red {
  --bs-card-border-width: 0 !important;
  --bs-card-bg: none !important;
  color: #fff !important;
  background-color: #d83831;
}

.programme .card .bg-blue2 {
  --bs-card-border-width: 0 !important;
  --bs-card-bg: none !important;
  color: #fff !important;
  background-color: #4481c0;
}

/* .programme .bg-blue {
  background-color: #1758a0;
}

.programme .bg-red {
  background-color: #d83831;
}

.programme .bg-blue2 {
  background-color: #4481c0;
} */

.programme-bg {
  background-image: linear-gradient(
      to right bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("../../public/assets/img/programme-bg.jpg");
  height: 530px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  padding: 0;
  --bs-gutter-x: 0 !important;
}

.title-facebook {
  font-family: "Barlow", sans-serif;
  font-weight: 900;
  font-size: 21px;
  text-transform: uppercase;
  color: #1758a0;
  line-height: 32px;
  margin-top: 30px;
  margin-bottom: 25px;
}

.button-contact {
  border: solid 1px #1758a0;
  background-color: #1758a0;
  color: #fff;
  padding: 6px 20px 8px;
  text-align: center;
  display: inline-block;
  font-size: 18px;
  text-transform: uppercase;
}

.offres {
  padding: 10px 0 30px 0;
  border-bottom: solid 8px #eb6009;
}

.offres-liste {
  background-color: #ebebeb;
  padding-bottom: 25px;
}

.offres-liste .cadre {
  background-color: #fff;
  border: solid 2px #1758a0;
  padding: 20px 10px 10px 10px;
  /* margin-top: 10px; */
  margin-bottom: 15px;
}

.button-offre {
  border: solid 2px #1758a0;
  border-radius: 30px;
  color: #1758a0;
  font-weight: bold;
  padding: 15px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.button-offre:hover {
  border: solid 2px #1758a0;
  border-radius: 30px;
  color: #d83831;
  font-weight: bold;
  padding: 15px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.button-poleemploi {
  border: solid 2px #1758a0;
  border-radius: 5px;
  color: #1758a0;
  font-weight: bold;
  padding: 15px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.button-poleemploi:hover {
  border: solid 2px #1758a0;
  background-color: #1758a0;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  padding: 15px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.button-recherche {
  border: solid 2px #d83831;
  background-color: #fff;
  border-radius: 5px;
  color: #d83831;
  font-weight: bold;
  /* padding: 2px 10px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.9rem;
  height: 40px;
  width: 40px;
}

.button-recherche:hover {
  border: solid 2px #1758a0;
  background-color: #1758a0;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  /* padding: 2px 10px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.9rem;
}

.map {
  width: 100%;
  height: 600px;
  padding: 0 !important;
  margin: 0 !important;
}

.searchbar {
  width: 640px !important;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto !important;
  position: relative;
}

.searchbar input {
  width: 100%;
  height: 100%;
  font-size: 0.9rem;
}

.searchbar .mb-3 {
  width: calc(100% - 40px);
  padding: 0 6px 0 0;
  height: 40px;
  margin: 0 !important;
}

/* The Modal (background) */
.modal-home {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content-home {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  text-align: center;
  border-radius: 17px;
  position: relative;
  z-index: 1999;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.button-inscrivez {
  background-color: #1758a0;
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: clamp(14px, 4vw, 24px);
  border-radius: 7px;
  margin-top: 10px;
  cursor: pointer;
}

.button-inscrivez:hover {
  color: #bcceff;
}

@media screen and (max-width: 768px) {
  .header-top {
    background-color: #1758a0;
    width: 100%;
    height: 475px;
    margin-top: -65px;
    /* display: flex;
    flex-direction: column;
    flex-wrap: wrap; */
    position: unset !important;
  }

  .navbartop a {
    text-decoration: none;
    text-transform: uppercase;
    /* background-color: #fff;
    color: #1758a0; */
    color: #fff;
    font-size: 18px;
    padding: 20px 35px;
    margin-bottom: 25px;
    font-family: "Lato", sans-serif;
  }

  .link-container {
    flex-direction: column;
    margin-top: 17px !important;
    margin-bottom: 17px !important;
  }

  .home-section-description span {
    color: #1758a0;
    background: #fff;
    display: inline-block;
    padding: 15px;
    font-size: 28px;
    font-weight: 900;
    font-family: "Barlow", sans-serif;
    text-transform: uppercase;
    line-height: 30px;
    margin: 3px 0;
    border-radius: 3px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
  }

  .title-barlow-bold {
    font-family: "Barlow", sans-serif;
    font-weight: 900;
    font-size: 68px;
    text-transform: uppercase;
    color: white;
    line-height: 90px;
    text-align: center;
    margin-bottom: 30px;
  }

  .title-page {
    font-family: "Barlow", sans-serif;
    font-weight: 900;
    font-size: 33px;
    text-transform: uppercase;
    color: #1758a0;
    line-height: 30px;
    margin-top: 30px;
    margin-bottom: 25px;
  }

  .footer {
    height: auto;
    background-color: #1758a0;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }

  .footer .pres-link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .footer .pres-link a:hover {
    text-decoration: none;
    border-bottom: unset;
  }

  .searchbar {
    width: 360px !important;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto !important;
    position: relative;
  }

  .searchbar input {
    width: 100%;
    height: 100%;
    font-size: 0.8rem;
  }

  /* Modal Content */
  .modal-content-home {
    width: 80%;
  }
}
